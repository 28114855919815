<template>
  <div class="page-content">
    <div class="content-box" v-loading="loading">
      <section-title title="申请信息" />
      <el-row class="u-flex mb-16">
        <div class="remark-text">申请岗位：</div>
        <el-select v-model="applyInfo.ChosenJobId" :disabled="isView">
          <el-option
            v-for="item in applyInfo.Details"
            :key="item.Id"
            :label="item.JobName"
            :value="item.JobId"
          ></el-option>
        </el-select>
      </el-row>
      <el-row class="u-flex mb-16">
        <div class="remark-text">实践时间：</div>
        <div class="color-base">
          {{ applyInfo.StartTime }} 至 {{ applyInfo.EndTime }}
        </div>
      </el-row>
      <el-row class="u-flex mb-16">
        <div class="remark-text">申请内容：</div>
        <div class="color-base">{{ applyInfo.Content }}</div>
      </el-row>
      <div v-if="applyInfo.ResumeId">
        <section-title title="个人简历" class="mb-24" />
        <div class="resume">
          <div class="flex p-28 resume-header">
            <el-avatar :src="Resume.Photo" :size="72"> </el-avatar>
            <div class="ml-24 u-flex-1 flex-column mr-24">
              <div>
                <span class="fs-16">{{ Resume.DisplayName }}</span>
                <span
                  :class="[
                    'resume-avatar-gender',
                    Resume.Gender == 2
                      ? 'el-icon-female resume-avatar-gender-female'
                      : 'el-icon-male resume-avatar-gender-male'
                  ]"
                ></span>
              </div>
              <div class="mt-12 fs-12">
                <span>{{ formartDate(Resume.BirthDay, "month") }}</span>
              </div>
              <el-row class="mt-8 fs-12">
                <el-col :span="8">
                  <span>期望职位：{{ Resume.ExpectJobName }}</span>
                </el-col>
                <el-col :span="8">
                  <span
                    >期望薪资：{{ Resume.SalaryMin }}-{{
                      Resume.SalaryMax
                    }}K</span
                  >
                </el-col>
                <el-col :span="8">
                  <span>期望地点：{{ Resume.ExpectWorkPlace }}</span>
                </el-col>
              </el-row>
              <el-row class="mt-8 fs-12">
                <el-col :span="8">
                  <span>联系电话：{{ Resume.ContactNumber }}</span>
                </el-col>
                <el-col :span="10">
                  <span>联系邮箱：{{ Resume.Email }}</span>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="dialog-content">
            <span class="content-title">基本信息</span>
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="info-item text-over">
                  <span class="item-type">性别：</span
                  ><span class="item-txt">{{ Resume.GenderDescription }}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item text-over">
                  <span class="item-type">出生年月：</span
                  ><span class="item-txt">{{
                    formartDate(Resume.BirthDay, "month")
                  }}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item text-over">
                  <span class="item-type">籍贯：</span
                  ><span class="item-txt">{{ Resume.NativePlace }}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item text-over">
                  <span class="item-type">婚姻状况：</span
                  ><span class="item-txt">{{ Resume.MaritalStatus }}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item text-over">
                  <span class="item-type">政治面貌：</span
                  ><span class="item-txt">{{ Resume.PoliticalStatus }}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item text-over">
                  <span class="item-type">最高学历：</span
                  ><span class="item-txt">{{ Resume.Education }}</span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="info-item">
                  <span class="item-type">现住地址：</span
                  ><span class=" present-address">{{
                    Resume.PresentAddress
                  }}</span>
                </div>
              </el-col>
            </el-row>
            <span class="content-title">工作经历</span>
            <el-row
              :gutter="20"
              v-for="item in Resume.ResumeCareerDtoList"
              :key="item.Id"
            >
              <el-col :span="24">
                <div class="info-item text-over flex u-row-between">
                  <span class="item-txt color-base fs-16">{{
                    item.JobName
                  }}</span>
                  <span class="item-txt color-light fs-12">
                    {{ formartDate(item.StartTime, "day") }} 至
                    {{ formartDate(item.EndTime, "day") }}
                  </span>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="info-item text-over">
                  <span class="item-type">描述：</span
                  ><span class="item-txt">{{ item.Description }}</span>
                </div>
              </el-col>
            </el-row>
            <span class="content-title">自我评价：</span>
            <el-row :gutter="20">
              <el-col :span="24">
                <div class="info-item text-over">
                  <span class="item-txt">{{ Resume.Introduction }}</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        该学生暂无简历
      </div>
    </div>
    <div class="footer">
      <el-button
        type="primary"
        :loading="btnLoading"
        v-if="!isView"
        @click="handleAgree"
        >同意</el-button
      >
      <el-button
        type="danger"
        :loading="btnLoading"
        v-if="!isView"
        @click="handleReject"
        >驳回</el-button
      >
      <el-button type="default" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import contentTools from "@/mixins/content-tools";
import SectionTitle from "@/components/SectionTitle.vue";
import { getApplyInfo, approve } from "@/api/jobApply";
import { getInfo } from "@/api/resume";
export default {
  components: {
    SectionTitle
  },
  mixins: [contentTools],
  data() {
    return {
      loading: false,
      id: "",
      applyInfo: {},
      Resume: "",
      isView: false,
      btnLoading: false
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.isView = Boolean(this.$route.query.view);
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        const res = await getApplyInfo(this.id);
        if (res.IsSuccess) {
          this.applyInfo = res.Result;
          let tempTime = [];
          tempTime.push(this.formartDate(this.applyInfo.StartTime, "day"));
          tempTime.push(this.formartDate(this.applyInfo.EndTime, "day"));
          this.applyInfo.Date = tempTime;
          this.applyInfo.ChosenJobId = this.applyInfo.Details[0].JobId;
          if (res.Result.ResumeId) {
            this.getResumeInfo(res.Result.ResumeId);
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async handleAgree() {
      try {
        this.btnLoading = true;
        let params = {
          Id: this.id,
          IsApproved: true,
          ChosenJobId: this.applyInfo.ChosenJobId,
          Opinion: ""
        };
        const res = await approve(params);
        if (res.IsSuccess) {
          this.$message.success("审批完成");
          this.$router.go(-1);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.btnLoading = false;
      }
    },
    async handleReject() {
      try {
        this.btnLoading = true;
        let params = {
          Id: this.id,
          IsApproved: false,
          ChosenJobId: this.applyInfo.ChosenJobId,
          Opinion: ""
        };
        const res = await approve(params);
        if (res.IsSuccess) {
          this.$message.success("审批完成");
          this.$router.go(-1);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.btnLoading = false;
      }
    },
    async getResumeInfo(id) {
      const res = await getInfo(id);
      if (res.IsSuccess) {
        this.Resume = res.Result;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-content {
  .content-box {
    width: 950px;
    height: calc(100% - 80px);
    margin: 0 auto;
    overflow-y: auto;
  }
  .empty {
    font-size: 24px;
    text-align: center;
    line-height: 100px;
  }
  .footer {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
    border-radius: 0px 0px 8px 8px;
  }
}
.resume {
  width: 80%;
  margin: 0 auto;
  .resume-header {
    background: linear-gradient(360deg, #14a495 0%, #598DF3 100%);
    color: #fff;
    border-radius: 8px 8px 0 0;
    .resume-avatar-gender {
      color: #fff;
      font-size: 16px;
      padding: 2px;
      margin-left: 8px;
      border-radius: 50%;
      &-male {
        background: #557df7;
      }
      &-female {
        background: #ff6677;
      }
    }
  }
}
.dialog-content {
  min-height: 400px;
  display: flex;
  padding: 10px 28px;
  flex-direction: column;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 8px 8px;
  .content-title {
    margin-bottom: 16px;
    &::before {
      content: "";
      vertical-align: -3px;
      border-radius: 2px;
      display: inline-block;
      width: 3px;
      height: 16px;
      background-color: #598DF3;
      margin-right: 5px;
    }
  }
}
.info-item {
  display: flex;
  // justify-content: space-between;
  align-items: baseline;
  padding: 10px 5px;
  .item-type {
    color: #999;
  }
}
</style>
